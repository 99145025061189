import { API } from 'aws-amplify';
import axios from 'axios';

export async function getCampaigns(includeArchived, datasetId) {
  const payload = {
    queryStringParameters: {
      includeArchived,
    },
  };
  if (datasetId) {
    payload.queryStringParameters.datasetId = datasetId;
  }
  return API.get('omw', `/marketing?includeArchived=${String(includeArchived)}`, payload);
}

export async function createCampaign(
  { campaignName, isPublished = false, clickUrl, categories, isDefault, isArchived, datasetId },
  file,
) {
  const campaignPayload = {
    body: {
      datasetId,
      campaignName,
      isPublished,
      clickUrl,
      imageName: file.name,
      categories,
      isDefault,
      isArchived,
    },
  };
  const uploadUrl = await API.get('omw', `/marketing/upload?filename=${file.name}`);
  try {
    await axios.put(uploadUrl, file, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    });
    campaignPayload.body.imageName = file.name;
    await API.post('omw', '/marketing', campaignPayload);
  } catch (err) {
    console.error(err);
  }
}

export async function getCampaignTotalCounts(campaign) {
  const payload = {
    queryStringParameters: {
      datasetId: campaign.datasetId,
    },
  };
  return API.get('omw', `/marketing/${campaign.campaignName}/counts`, payload);
}

export async function getCampaignDailyCounts(campaign, dateFrom, dateTo) {
  const payload = {
    queryStringParameters: {
      datasetId: campaign.datasetId,
      dateFrom,
      dateTo,
    },
  };
  return API.get('omw', `/marketing/${campaign.campaignName}/counts`, payload);
}

export async function updateCampaign({ campaignName, clickUrl, categories, isDefault, datasetId }) {
  const campaignPayload = {
    body: {
      clickUrl,
      categories,
      isDefault,
      datasetId,
    },
  };
  return API.patch('omw', `/marketing/${campaignName}`, campaignPayload);
}

export async function archiveCampaign(campaign) {
  const payload = {
    queryStringParameters: {
      datasetId: campaign.datasetId,
      isArchived: 'true',
    },
  };
  return API.patch('omw', `/marketing/${campaign.campaignName}/archive`, payload);
}
export async function unarchiveCampaign(campaign) {
  const payload = {
    queryStringParameters: {
      datasetId: campaign.datasetId,
      isArchived: 'false',
    },
  };
  return API.patch('omw', `/marketing/${campaign.campaignName}/archive`, payload);
}

export async function unpublishCampaign(campaign) {
  const payload = {
    queryStringParameters: {
      datasetId: campaign.datasetId,
    },
  };
  return API.patch('omw', `/marketing/${campaign.campaignName}/unpublish`, payload);
}

export async function publishCampaign(campaign) {
  const payload = {
    queryStringParameters: {
      datasetId: campaign.datasetId,
    },
  };
  return API.patch('omw', `/marketing/${campaign.campaignName}/publish`, payload);
}

export async function makeCampaignDefault(campaign) {
  return API.patch('omw', `/marketing/${campaign.campaignName}/default`, payload);
}

export async function restrictCampaign(campaign) {
  const payload = {
    queryStringParameters: {
      datasetId: campaign.datasetId,
    },
  };
  return API.patch('omw', `/marketing/${campaign.campaignName}/restrict`, payload);
}
