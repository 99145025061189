<script>
import saveFile from 'save-as-file';
import Papa from 'papaparse';
import { DateTime } from 'luxon';

export default {
  name: 'GlobalUtilsMixin',
  methods: {
    numberWithCommas(x) {
      if (x == null || x == undefined) return 0;
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    random_rgba() {
      let o = Math.round,
        r = Math.random,
        s = 255; // eslint-disable-line
      return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + r().toFixed(1) + ')';
    },
    exportCsv(title, data, keys) {
      const papaConfig = {
        header: true,
        quotes: true,
      };
      if (keys) {
        papaConfig.columns = keys;
      }
      const csv = Papa.unparse(data, papaConfig);
      const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' });
      const now = DateTime.now(); // eslint-disable-line
      saveFile(blob, `${title}_${now.toFormat('dd-MM-yyyy_hh_mm_ss')}.csv`);
    },
    isValidDate(d) {
      return d instanceof Date && !isNaN(d);
    },
    convertArrayToCSV(arrayToConvert) {
      if (arrayToConvert && arrayToConvert.length > 0) {
        let output = '';
        arrayToConvert.forEach((item) => {
          output += `${item},`;
        });
        return output.slice(0, -1);
      } else {
        return '';
      }
    },
    showToast(msg, successful) {
      this.$buefy.toast.open({
        message: msg,
        type: successful ? 'is-success' : 'is-danger',
        duration: 5000,
      });
    },
  },
};
</script>
