/*
  Used to deal with maintaining state for logged in users
*/
import Vue from 'vue';
import Vuex from 'vuex';
import { modules, stateToPersist } from './index';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',

  modules,
  plugins: [
    createPersistedState({
      paths: stateToPersist,
      storage: window.sessionStorage,
    }),
  ],
});

export default store;
