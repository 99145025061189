const config = {
  apiGateway: {
    REGION: 'eu-west-2',
    URL: 'https://2gmfqmw30f.execute-api.eu-west-2.amazonaws.com/mitie-prod',
  },
  cognito: {
    REGION: 'eu-west-2',
    USER_POOL_ID: 'eu-west-2_rfbERTkfn',
    APP_CLIENT_ID: '7695c3urvnjeklli3ufi0f5oqq',
    IDENTITY_POOL_ID: 'undefined',
  },
};
export default config;
