<!--
  Component for logging into the portal
-->
<template>
  <auth-container>
    <template #authForm>
      <form @submit.prevent="onLogin" @keyup.enter="onLogin()">
        <b-field>
          <b-input
            v-model="email"
            class="has-icons-left"
            type="email"
            :placeholder="$t('user-admin-email')"
            autocomplete="email"
            icon="at"
          ></b-input>
        </b-field>
        <b-field>
          <b-input
            v-model="password"
            class="has-icons-left"
            type="password"
            :placeholder="$t('user-admin-password')"
            autocomplete="current-password"
            icon="key"
          ></b-input>
        </b-field>
        <div class="buttons is-centered">
          <b-button
            type="is-info"
            :class="{ 'is-loading': isLoading }"
            @click="onLogin"
          >
            {{ $t('user-admin-login-button-label') }}
          </b-button>
        </div>
        <br />
        <router-link :to="{ name: 'passwordReset' }" class="has-text-primary">{{
          $t('user-admin-reset-password-link')
        }}</router-link>
      </form>
      <div v-if="loginError">
        <span
          :class="{
            help: true,
            'is-danger': true,
            'has-text-weight-bold': true,
          }"
        >
          {{ loginError }}
        </span>
      </div>
    </template>
  </auth-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { mapActions } from 'vuex';
import AuthContainer from './AuthContainer.vue';

export default defineComponent({
  name: 'SignIn',
  components: {
    AuthContainer,
  },
  data() {
    return {
      isLoading: false,
      email: '',
      password: '',
      loginSuccess: null,
      loginError: null,
    };
  },
  methods: {
    ...mapActions('storeAuth', ['login']),
    async onLogin() {
      this.isLoading = true;
      this.loginError = null;
      const formData = {
        email: this.email,
        password: this.password,
      };
      try {
        const loggedIn = await this.login({
          email: formData.email,
          password: formData.password,
        });
        if (loggedIn) {
          this.$router.replace({
            name: 'dashboard',
          });
        }
      } catch (err) {
        switch (err.code) {
          case 'UserNotFoundException':
            this.loginError = this.$t('user-admin-not-found');
            break;
          case 'NotAuthorizedException':
            this.loginError = this.$t('user-admin-incorrect-pssword');
            break;
          default:
            this.loginError = this.$t('user-admin-default-login-error');
            break;
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
});
</script>
