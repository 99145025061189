import Vue from 'vue';

import { getCampaigns, getCampaignTotalCounts } from '@/services/marketing';

export default {
  namespaced: true,
  state: {
    campaigns: [],
    campaignTotalCounts: {},
  },
  mutations: {
    SET_CAMPAIGNS(state, payload) {
      state.campaigns = payload;
    },
    SET_CAMPAIGN_TOTAL_COUNTS(state, payload) {
      if (payload?.campaignName) {
        Vue.set(state.campaignTotalCounts, payload.campaignName, payload.data);
      }
    },
  },
  actions: {
    async getMarketingCampaigns({ commit }, isArchived) {
      const campaigns = await getCampaigns(isArchived);
      commit('SET_CAMPAIGNS', campaigns);
    },
    getCampaignTotalCounts({ state, commit }) {
      state.campaigns.forEach(async (campaign) => {
        const result = await getCampaignTotalCounts(campaign);
        commit('SET_CAMPAIGN_TOTAL_COUNTS', {
          campaignName: campaign.campaignName,
          datasetId: campaign.datasetId,
          data: result,
        });
      });
    },
  },
  getters: {
    campaigns: (state) => state.campaigns,
    campaignTotalCounts: (state) => state.campaignTotalCounts,
  },
};
