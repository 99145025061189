import { library } from '@fortawesome/fontawesome-svg-core';

import { faText } from '@fortawesome/pro-solid-svg-icons/faText';
import { faFileCheck } from '@fortawesome/pro-solid-svg-icons/faFileCheck';
import { faFileTimes } from '@fortawesome/pro-solid-svg-icons/faFileTimes';
import { faWarehouseAlt } from '@fortawesome/pro-solid-svg-icons/faWarehouseAlt';

import { faFileDownload } from '@fortawesome/pro-duotone-svg-icons/faFileDownload';
import { faLightbulb } from '@fortawesome/pro-duotone-svg-icons/faLightbulb';
import { faAt } from '@fortawesome/pro-duotone-svg-icons/faAt';
import { faKey } from '@fortawesome/pro-duotone-svg-icons/faKey';
import { faChartPie } from '@fortawesome/pro-duotone-svg-icons/faChartPie';
import { faUpload } from '@fortawesome/pro-duotone-svg-icons/faUpload';
import { faCarSide } from '@fortawesome/pro-duotone-svg-icons/faCarSide';
import { faStreetView } from '@fortawesome/pro-duotone-svg-icons/faStreetView';
import { faDatabase } from '@fortawesome/pro-duotone-svg-icons/faDatabase';
import { faTools } from '@fortawesome/pro-duotone-svg-icons/faTools';
import { faQuestionCircle } from '@fortawesome/pro-duotone-svg-icons/faQuestionCircle';
import { faServer } from '@fortawesome/pro-duotone-svg-icons/faServer';
import { faCalendarMinus } from '@fortawesome/pro-duotone-svg-icons/faCalendarMinus';
import { faAngleLeft } from '@fortawesome/pro-duotone-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/pro-duotone-svg-icons/faAngleRight';
import { faCalendarPlus } from '@fortawesome/pro-duotone-svg-icons/faCalendarPlus';
import { faSitemap } from '@fortawesome/pro-duotone-svg-icons/faSitemap';
import { faTimes } from '@fortawesome/pro-duotone-svg-icons/faTimes';
import { faPencilAlt } from '@fortawesome/pro-duotone-svg-icons/faPencilAlt';
import { faDownload } from '@fortawesome/pro-duotone-svg-icons/faDownload';
import { faArrowUp } from '@fortawesome/pro-duotone-svg-icons/faArrowUp';
import { faCalendarDay } from '@fortawesome/pro-duotone-svg-icons/faCalendarDay';
import { faParking } from '@fortawesome/pro-duotone-svg-icons/faParking';
import { faRoute } from '@fortawesome/pro-duotone-svg-icons/faRoute';
import { faWindowClose } from '@fortawesome/pro-duotone-svg-icons/faWindowClose';
import { faChartBar } from '@fortawesome/pro-duotone-svg-icons/faChartBar';
import { faChartLine } from '@fortawesome/pro-duotone-svg-icons/faChartLine';
import { faCloudUploadAlt } from '@fortawesome/pro-duotone-svg-icons/faCloudUploadAlt';
import { faEye } from '@fortawesome/pro-duotone-svg-icons/faEye';
import { faTag } from '@fortawesome/pro-duotone-svg-icons/faTag';
import { faArrowsAltH } from '@fortawesome/pro-duotone-svg-icons/faArrowsAltH';
import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons/faTimesCircle';
import { faUndo } from '@fortawesome/pro-duotone-svg-icons/faUndo';
import { faTrash } from '@fortawesome/pro-duotone-svg-icons/faTrash';
import { faCaretRight } from '@fortawesome/pro-duotone-svg-icons/faCaretRight';
import { faCalendar } from '@fortawesome/pro-duotone-svg-icons/faCalendar';
import { faMinus } from '@fortawesome/pro-duotone-svg-icons/faMinus';
import { faSadTear } from '@fortawesome/pro-duotone-svg-icons/faSadTear';
import { faClipboardCheck } from '@fortawesome/pro-duotone-svg-icons/faClipboardCheck';
import { faRuler } from '@fortawesome/pro-duotone-svg-icons/faRuler';
import { faLongArrowAltUp } from '@fortawesome/pro-duotone-svg-icons/faLongArrowAltUp';
import { faLongArrowAltDown } from '@fortawesome/pro-duotone-svg-icons/faLongArrowAltDown';
import { faWarehouse } from '@fortawesome/pro-duotone-svg-icons/faWarehouse';
import { faSearchMinus } from '@fortawesome/pro-duotone-svg-icons/faSearchMinus';
import { faSearchPlus } from '@fortawesome/pro-duotone-svg-icons/faSearchPlus';
import { faFileCsv } from '@fortawesome/pro-duotone-svg-icons/faFileCsv';
import { faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons/faExclamationTriangle';

import { faFunnelDollar } from '@fortawesome/pro-duotone-svg-icons/faFunnelDollar';
import { faMapMarked } from '@fortawesome/pro-duotone-svg-icons/faMapMarked';
import { faComments } from '@fortawesome/pro-duotone-svg-icons/faComments';
import { faLock } from '@fortawesome/pro-duotone-svg-icons/faLock';
import { faHeadset } from '@fortawesome/pro-duotone-svg-icons/faHeadset';
import { faUserHardHat } from '@fortawesome/pro-duotone-svg-icons/faUserHardHat';
import { faMouseAlt } from '@fortawesome/pro-duotone-svg-icons/faMouseAlt';
import { faSyncAlt } from '@fortawesome/pro-duotone-svg-icons/faSyncAlt';
import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle';
import { faExclamation } from '@fortawesome/pro-duotone-svg-icons/faExclamation';
import { faCheck } from '@fortawesome/pro-duotone-svg-icons/faCheck';
import { faSmile } from '@fortawesome/pro-duotone-svg-icons/faSmile';
import { faSmileBeam } from '@fortawesome/pro-duotone-svg-icons/faSmileBeam';
import { faSearch } from '@fortawesome/pro-duotone-svg-icons/faSearch';
import { faCalendarAlt } from '@fortawesome/pro-duotone-svg-icons/faCalendarAlt';

library.add(
  faSmile,
  faSmileBeam,
  faMinus,
  faSearch,
  faCalendarAlt,
  faExclamationCircle,
  faExclamation,
  faMouseAlt,
  faMapMarked,
  faFileDownload,
  faLightbulb,
  faAt,
  faKey,
  faMapMarked,
  faFunnelDollar,
  faComments,
  faChartPie,
  faUpload,
  faCarSide,
  faStreetView,
  faLock,
  faDatabase,
  faTools,
  faQuestionCircle,
  faServer,
  faCalendarMinus,
  faAngleLeft,
  faAngleRight,
  faCalendarPlus,
  faSitemap,
  faTimes,
  faCheck,
  faPencilAlt,
  faDownload,
  faArrowUp,
  faCalendarDay,
  faParking,
  faRoute,
  faSearch,
  faWindowClose,
  faChartBar,
  faChartLine,
  faCloudUploadAlt,
  faEye,
  faTag,
  faArrowsAltH,
  faTimesCircle,
  faUndo,
  faTrash,
  faCaretRight,
  faText,
  faCalendar,
  faMinus,
  faSadTear,
  faUserHardHat,
  faFileCheck,
  faFileTimes,
  faClipboardCheck,
  faRuler,
  faLongArrowAltUp,
  faLongArrowAltDown,
  faWarehouseAlt,
  faWarehouse,
  faSearchMinus,
  faSearchPlus,
  faFileCsv,
  faSyncAlt,
  faHeadset,
  faExclamationTriangle,
);
