/*
  Used to deal with maintaining state for logged in users
*/
import { Auth, API } from 'aws-amplify';
import copy from 'fast-copy';

import router from '@/router';

export default {
  namespaced: true,
  state: {
    isAuthenticated: false,
    userId: undefined,
    user: undefined,
    email: undefined,
    users: [],
    products: {},
    validated: false,
    selectedTab: 0,
    authUser: undefined,
  },
  mutations: {
    SET_IS_AUTHENTICATED(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    SET_AUTH_USER(state, authUser) {
      state.authUser = authUser;
    },
    SET_SELECTED_TAB(state, tab) {
      state.selectedTab = tab;
    },
    SET_VALIDATED(state, validated) {
      state.validated = validated;
    },
    ADD_PRODUCTS(state, products) {
      state.products = products;
    },
    DISCARD_PRODUCTS(state) {
      state.products = {};
    },
    AUTH_USER(state, userData) {
      state.userId = userData.userId;
      state.email = userData.email;
    },
    STORE_USER_DATA(state, user) {
      state.user = user;
    },
    CLEAR_AUTH_DATA(state) {
      state.userId = null;
      state.user = null;
      state.email = null;
      state.selectedAppointment = null;
      state.appointments = {};
    },
    STORE_USER: (state, remoteUser) => {
      // Search for an existing user with same Id and replace
      const users = copy(state.users);
      let existing = false;
      for (let i = 0; i < users.length; i++) {
        if (users[i].key === remoteUser.key) {
          users.splice(i, 1, remoteUser);
          existing = true;
          break;
        }
      }
      // New user to add to the array
      if (!existing) {
        users.push(remoteUser);
      }
      state.users = users;
    },
    DISCARD_USERS: (state) => {
      state.users = [];
    },
    ADD_USER: (state, user) => {
      let idx = state.users.findIndex(
        (existingUser) => user.sub === existingUser.sub,
      );
      if (idx === -1) idx = state.users.length;
      state.users.splice(idx + 1, 1, user);
    },
  },
  actions: {
    // eslint-disable-next-line no-empty-pattern
    async updatePassword({}, { email, verificationCode, password }) {
      await Auth.forgotPasswordSubmit(email, verificationCode, password);
    },
    // eslint-disable-next-line no-empty-pattern
    async signup({}, userData) {
      await Auth.signUp({
        username: userData.email,
        password: userData.password,
        attributes: {
          email: userData.email,
        },
      });
    },
    async verifyUser({ commit, state, dispatch }, { username, code }) {
      try {
        await Auth.confirmSignUp(username, code);
      } catch (err) {
        console.error(err);
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async resendVerificationCode({}, { username }) {
      try {
        await Auth.resendSignUp(username);
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    setAuthUser({ commit }, authUser) {
      commit('SET_AUTH_USER', authUser);
    },
    setSelectedTab({ commit }, tab) {
      commit('SET_SELECTED_TAB', tab);
    },
    async getProducts({ commit }) {
      const productResults = await API.get('omw', '/products');
      commit('ADD_PRODUCTS', productResults);
      return productResults;
    },
    // eslint-disable-next-line no-empty-pattern
    async resetPassword({}, email) {
      await Auth.forgotPassword(email);
    },
    async setNewPassword({}, { cognitoUser, password }) {
      await Auth.completeNewPassword(cognitoUser, password);
    },
    async login({ commit, dispatch }, userData) {
      const user = await Auth.signIn(userData.email, userData.password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        router.push({
          name: 'passwordReset',
          params: {
            completeNewPassword: true,
            cognitoUser: user,
            userEmail: userData.email,
          },
        });
        return false;
      }
      commit('SET_VALIDATED', user.attributes.email_verified);
      commit('SET_IS_AUTHENTICATED', true);
      commit('AUTH_USER', {
        userId: user.username,
        email: user.attributes.email,
      });
      await dispatch('fetchUser', user.userId, false);
      await commit('DISCARD_PRODUCTS');
      await dispatch('getProducts');
      return true;

      // Clear any products before retrieving new ones
    },
    async logout({ commit }) {
      try {
        commit('CLEAR_AUTH_DATA');
        commit('DISCARD_USERS');
        commit('DISCARD_PRODUCTS');
        commit('SET_IS_AUTHENTICATED', false);
        await Auth.signOut();
      } catch (err) {
        // nothing to do
      }
    },
    async fetchUser({ state, commit }) {
      const userId = state.userId;
      const result = await API.get('omw', `/users/${userId}`);
      commit('STORE_USER_DATA', copy(result));
    },
    async fetchUsers({ dispatch, state, commit }) {
      const result = await API.get('omw', `/users`);
      await dispatch('discardUsers');
      result.forEach((user) => {
        commit('ADD_USER', user);
      });
    },
    discardUsers({ commit }) {
      commit('DISCARD_USERS');
    },
  },
  getters: {
    user: (state) => state.user,
    users: (state) => state.users,
    userId: (state) => state.userId,
    products: (state) => state.products,
    selectedTab: (state) => state.selectedTab,
    authUser: (state) => state.authUser,
    isAuthenticated: (state) => state.isAuthenticated,
  },
};
