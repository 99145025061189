var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('auth-container',{scopedSlots:_vm._u([{key:"authForm",fn:function(){return [_c('validation-observer',{ref:"passValObs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-field',{attrs:{"label":_vm.instructions}},[_c('validation-provider',{ref:"passResetValProv",attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"name":"email","icon":"at"},on:{"input":_vm.clearMessage},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-field',[(!_vm.submitted && !_vm.isVerifyResubmission)?_c('b-button',{attrs:{"disabled":invalid,"type":"is-primary"},on:{"click":_vm.sendPasswordResetEmail}},[_vm._v(" "+_vm._s(_vm.$t('user-admin-reset-password-button-label'))+" ")]):_c('div',[_c('label',{staticClass:"label has-text-left"},[_vm._v(_vm._s(_vm.$t('user-admin-password-label')))]),_c('validation-provider',{attrs:{"rules":"passwordValidator|required","name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',[_c('b-input',{attrs:{"id":"password","type":"password","icon":"key","autocomplete":"new-password"},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1),_c('span',{staticClass:"help is-danger has-text-weight-bold",staticStyle:{"min-height":"1rem"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(!_vm.isVerifyResubmission)?_c('div',[_c('label',{staticClass:"label has-text-left"},[_vm._v(_vm._s(_vm.$t('user-admin-verification-code')))]),_c('b-field',[_c('validation-provider',{attrs:{"rules":"length:6|required","name":"verificationCode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"id":"verification-code","type":"numeric"},model:{value:(_vm.verificationCode),callback:function ($$v) {_vm.verificationCode=$$v},expression:"verificationCode"}}),_c('span',{staticClass:"help is-danger has-text-weight-bold",staticStyle:{"min-height":"1rem"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),_c('b-button',{attrs:{"disabled":invalid,"type":"is-primary","loading":_vm.loading},on:{"click":_vm.submitNewPassword}},[_vm._v(" "+_vm._s(_vm.$t('user-admin-update-password-button-label'))+" ")])],1)],1),(_vm.error)?_c('p',{staticClass:"is-danger"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):(_vm.submitted)?_c('p',[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e(),(_vm.showLoginLink)?_c('router-link',{staticClass:"mb-1",attrs:{"to":"signin"}},[_vm._v(_vm._s(_vm.$t('user-admin-verified-go-to-login'))+" ")]):_vm._e()],1)]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }