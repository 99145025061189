<template>
  <div v-cloak id="app" class="has-background-white-ter">
    <div id="content-wrap">
      <the-header></the-header>
      <transition name="fade" mode="out-in">
        <keep-alive :exclude="['OmwStats', 'Admin', 'CampaignStats']">
          <router-view class="content"></router-view>
        </keep-alive>
      </transition>
      <message v-if="$configData.messages" />
    </div>
    <the-footer id="footer">
      <div style="display: flex; justify-content: center; align-items: center">
        <strong class="mr-2">{{ $t('general-powered-by') }}</strong>
        <a href="https://www.leadent.digital" target="_new">
          <img :src="ldLogo" style="max-height: 30px" />
        </a>
      </div>
    </the-footer>
  </div>
</template>

<script>
import TheHeader from '@/components/layout/TheHeader.vue';

export default {
  name: 'App',
  components: {
    TheHeader,
    Message: () => import(/* webpackPreload: true */ '@/components/layout/Message.vue'),
    TheFooter: () => import(/* webpackPreload: true */ '@/components/layout/TheFooter.vue'),
  },
  computed: {
    ldLogo() {
      return require('@/assets/img/ldlogo.webp');
    },
  },
  watch: {
    $route(to) {
      document.title = to.meta.title || this.$t('default-app-title');
    },
  },
};
</script>

<style lang="scss">
@import './sass/buefy-init.scss';

html[theme='dark-mode'] {
  filter: invert(1) hue-rotate(180deg);
}

html[theme='dark-mode'] img {
  filter: invert(1) hue-rotate(180deg);
}

html {
  transition: color 300ms, background-color 300ms;
}

body,
html {
  margin: 0;
}

#app {
  position: relative;
  min-height: 99vh;
}

#content-wrap {
  padding-bottom: 6rem;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 6rem;
}

a {
  cursor: pointer;
  text-decoration: none;
}

.verticalLine {
  border-right: thin solid lightgray;
  border-left: thin solid lightgray;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

li {
  list-style-type: none !important;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

[v-cloak] > * {
  display: none;
}

[v-cloak]::before {
  content: 'loading…';
}

.hero .subtitle {
  padding: 3rem 0;
  line-height: 1.5;
}

.hero {
  width: 100wh;
  height: 50vh;
  display: flex;
}

img.shadow {
  -webkit-box-shadow: 3px 3px 12px 1px rgba(0, 0, 0, 0.71);
  -moz-box-shadow: 3px 3px 12px 1px rgba(0, 0, 0, 0.71);
  box-shadow: 3px 3px 12px 1px rgba(0, 0, 0, 0.71);
}

button {
  font-weight: bold;
}

a {
  font-weight: bold;
}
</style>
